import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#f9f9f9", py: 4, fontFamily: "monospace" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          {/* Quick Links Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Quick Links
              </Typography>
              {[
                { name: "About Us", path: "/about" },
                { name: "Contact Us", path: "/contact" },
                { name: "Offerings", path: "/offerings" },


              ].map((link) => (
                <Typography key={link.name} variant="body2" sx={{ mb: 1 }}>
                  <RouterLink
                    to={link.path}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    {link.name}
                  </RouterLink>
                </Typography>
              ))}
            </Box>
          </Grid>
          {/* Our Offerings Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2,  }}>
                Contact
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>For Businesses:</strong>
              </Typography>
              <Typography>
                <a
                  href="mailto:business@techietalents.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  business@techietalents.com
                </a>
              </Typography>
            </Box>
          </Grid>


          {/* Address and Contact Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Address
              </Typography>
              <Typography
                variant="h5"
                noWrap
                component={RouterLink}
                to="/"
                sx={{
                  fontFamily: "Metropolis, sans-serif",
                  fontWeight: 800,
                  letterSpacing: ".1rem",
                  color: "#22004C",
                  textDecoration: "none",
                  ml: 1,
                  fontSize: "2.5rem",
                }}
              >
                Techie <span style={{ color: "#8EEAEA" }}>Talents</span>
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                No.10, Ramamurthy Nagar, Dodda Banaswadi, Bangalore - 560043
              </Typography>


            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* Footer Bottom */}
      <Box
        sx={{
          textAlign: "center",
          mt: 4,
          py: 2,
          borderTop: "1px solid #ddd",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} techietalents.com All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
